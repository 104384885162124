import React from "react";

const InstagramLink = (props) => {

    if (!props.igLink) {
        return ""
    }

    const link = 'https://www.instagram.com/p/' + props.igLink;

    return (
        <tr>
          <td className="cellicon"><a target='_blank' href={link}><span
            className="fa-brands fa-instagram fa-2x icon-color"></span></a></td>
          <td className="celltext"><a target='_blank' href={link}>Findis.dk Instagram anmeldelse</a></td>
        </tr>
    )

}

export default InstagramLink;
