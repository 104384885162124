import React from "react";

const DeepLink = (props) => {

    const link = '/ishus/' + props.id;

    return (
        <tr>
          <td className="cellicon"><a href={link}><span className="fa-regular fa-link-simple fa-2x icon-color"></span></a></td>
          <td className="celltext"><a href={link}>Findis.dk direkte link</a></td>
        </tr>
    )

}

export default DeepLink;
