import React from "react";

const CommercialProtection = (props) => {

    if (!props.protection) {
        return ""
    }

    const link = 'https://datacvr.virk.dk/artikel/reklamebeskyttelse';

    return (
        <tr>
          <td className="cellicon"><a target='_blank' href={link}><span className="fa-regular fa-shield-xmark fa-2x icon-color"></span></a></td>
          <td className="celltext"><a target='_blank' href={link}>Reklamebeskyttelse</a></td>
        </tr>
    )
}

export default CommercialProtection;
