import React from "react";
import GoogleMapsLink from "./GoogleMapsLink";
import DeepLink from "./DeepLink";
import CommercialProtection from "./CommercialProtection";
import ContactFindIsLink from "./ContactFindIsLink";
import InstagramLink from "./InstagramLink";
import IcecreamBrand from "./IcecreamBrand";
import IcecreamType from "./IcecreamType";
import Rating from "./Rating";

const InfoBox = (props) => {

    return (
      <div style={{minWidth:'40px'}}>
        <div style={{padding:'2px 1px 2px 1px'}}>
          <div><strong>{props.selected.name}</strong> ({props.selected.id})</div>
          <div style={{padding:'2px 1px 7px 1px'}}>{props.selected.address}</div>
        </div>
        <div>
          <table><tr>
            <td valign="top"><table>
              <GoogleMapsLink address={props.selected.address} />
              <DeepLink id={props.selected.id} />
              <CommercialProtection protection={props.selected.commercialProtection}/>
              <ContactFindIsLink id={props.selected.id} name={props.selected.name}/>
              <InstagramLink igLink={props.selected.igLink}/>
              <IcecreamBrand key={props.selected.id} value={props.selected.brand}/>
              <IcecreamType key={props.selected.id} value={props.selected.type}/>
            </table>
            </td>
            <td valign="top"><Rating key={props.selected.id} score={props.selected.score}
                                     ratingvalue={props.selected.ratingValue} ratingcount={props.selected.ratingCount}/></td>
          </tr></table>
        </div>
      </div>
    )
}

export default InfoBox;
