import React from "react";

const InstagramProfileLink = () => {

    const link = 'https://www.instagram.com/' + encodeURI('findis.dk');

    return (
      <div className="quick-icon-box" style={{top: '145px'}}>
        <div className="quick-icon-cell">
          <a target='_blank' href={link} title="Gå til findis.dk's Instagram profil"><span className="fa-brands fa-instagram fa-2x icon-color"></span></a>
        </div>
      </div>
    )

}

export default InstagramProfileLink;
