import React from "react";

const GoogleMapsLink = (props) => {

    const link = 'https://www.google.com/maps/dir/?api=1&destination=' + encodeURI(props.address);

    return (
        <tr>
          <td className="cellicon"><a target='_blank' href={link}><span className="fa-regular fa-location-dot fa-2x icon-color"></span></a></td>
          <td className="celltext"><a target='_blank' href={link}>Google map rute</a></td>
        </tr>
    )

}

export default GoogleMapsLink;
