import React from "react";

const LogoLink = () => {

    const link = '/';

    return (
      <div className="logo-box">
        <div className="logo-cell">
          <a target='_self' href={link}><img src="/media/logo.png" width={240}></img></a>
        </div>
      </div>
    )

}

export default LogoLink;
