import React, { useState } from "react";
import ReactDOM from 'react-dom';
import {CookiesProvider} from "react-cookie";
import {BrowserRouter as Router, Route, useParams} from "react-router-dom"
import WebHome from './WebHome';

const GetIcpId = () => {
  const { id } = useParams();

  return (
    <WebHome icpId={id}/>
  );
}

ReactDOM.render(
    <CookiesProvider>
      <Router>
        <Route path={["/ishus/:id", "/"]}>
          <GetIcpId/>
        </Route>
      </Router>
    </CookiesProvider>,
    document.getElementById('findis-mountpoint')
);
