import React from "react";

const GeneralMailLink = () => {

    const link = 'mailto:info@findis.dk?subject=' +
        encodeURI('Mail fra Findis.dk...');

    return (
      <div className="quick-icon-box" style={{top: '190px'}}>
        <div className="quick-icon-cell">
          <a target='_blank' href={link} title="Send mail til findis.dk"><span className="fa-regular fa-envelope fa-2x icon-color"></span></a>
        </div>
      </div>
    )

}

export default GeneralMailLink;
