import React from "react";

const Rating = (props) => {

    const scoreValueAsText = (props.score === undefined) || (props.score === null) ?
        '' :
        Number.parseFloat(props.score.average)
            .toFixed(1)
            .replace(/\./g, ',');

    return (
        (props.score !== undefined) &&
        (props.score !== null) &&
        (
          <div>
            <div id="scorevalue">{scoreValueAsText}</div>
            <div id="scoretext">(Findis.dk's vurdering)</div>
          </div>
        )
    )
}

export default Rating;
