import React, { useState, useEffect } from "react";
import '../../app/css/Main.css'
import '../../app/css/Menu.css'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {getFromBackend} from '../../common/Backend.js'
import Popup from 'react-popup';
import InfoBox from "./info/InfoBox";
import { useCookies } from "react-cookie";
import InstagramProfileLink from "./common/InstagramProfileLink";
import GeneralMailLink from "./common/GeneralMailLink";
import { slide as Menu } from 'react-burger-menu'
import LogoLink from "./common/LogoLink";
import ShowFilter from "./common/ShowFilter";
import ResetLink from "./common/ResetLink";

const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 20000,
  maximumAge: 1000
};

const gdprText = 'Findis.dk bruger data fra CVR-registeret. ' +
  'Ved at klikke videre accepterer du, at du ikke vil kontakte firmaer med reklamebeskyttelse. ' +
  'Findis.dk bruger egne cookies samt cookies fra tredjeparter til at ' +
  'huske dine indstillinger, til at foretage trafikmåling og til at vise målrettede annoncer til dig. ' +
  'Ved at klikke videre på hjemmesiden giver du samtykke til denne brug af cookies i de kommende 30 dage. ' +
  'Ønsker du ikke at acceptere brugen af cookies, er det nødvendigt at forlade hjemmesiden eller blokere cookies i din browser. ' +
  'Du kan altid tilbagekalde dit samtykke ved at blokere brugen af cookies i din browser. ' +
  '';

function errorConsole(err) {
  console.warn('Error(' + err.code + '): ' + err.message);
}

function errorPopup(err) {
  alert('Fejl: ' + err.message)
}

const WebHome = (props) => {

  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [defaultCenter, setDefaultCenter] = useState({lat: 55.95, lng: 12.33});
  const [defaultZoom, setDefaultZoom] = useState(7);
  const [browserLocation, setBrowserLocation] = useState(null);
  const [showLocation, setShowLocation] = useState(false);
  const [watchId, setWatchId] = useState(null);
  const [browserZoom, setBrowserZoom] = useState(10);
  const [filtericps, setFiltericps] = useState(null);
  const [visible, setVisible] = useState(true);

  const [mapLoaded, setMapLoaded] = useState(false);
  const [markerAlreadyMounted, setMarkerAlreadyMounted] = useState(false);
  const [center, setCenter] = useState([]);
  const [zoom, setZoom] = useState(6);
  const [apiKey, setApiKey] = useState('AIzaSyCxtbA8tfmcJMaRT6gbmPhIFH04ru-sTXE');

  const [cookies, setCookie] = useCookies(['popup']);

  const [icpFilterScore, setIcpFilterScore] = useState(1);

  // General mount - once
  useEffect(() => {

    // Get all filter icps
    fetchIcpData();

    // Privacy popup
    if (cookies.CONSENT == null || !cookies.CONSENT) {
      Popup.alert(gdprText);
      setCookie('CONSENT', true, { path: '/', maxAge: (30 * 24 * 60 * 60) });
    }

  }, []);

  // Position mount - when showLocation changes
  useEffect(() => {

    // Get current position by browser
    position();

    if (showLocation) {
      // Reposition every 60 sec
      const timer = window.setInterval(() => {
        reposition();
      }, 60*1000);

      return () => window.clearTimeout(timer);
    }

  }, [showLocation]);

  function position() {
    navigator &&
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setShowLocation(true);
        setBrowserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      },
      errorConsole,
      geolocationOptions,
    );
  }

  function reposition() {
    navigator &&
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setBrowserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      },
      errorConsole,
      geolocationOptions,
    );
  }

  function onMarkerClick(props, marker) {
    setMarkerAlreadyMounted(true);
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  }

//  onMarkerReady (marker) {
  function onMarkerMounted(element) {
    if (element !== null) {
      setTimeout(
        function () {
          onMarkerClick(element.props, element.marker)
        },
        1000
      );
      document.title = 'Findis.dk - ' + element.props.name + ' (' + element.props.id + ')';
    }
  }

  function handleMapIdle() {
    setMapLoaded(true)
  }

  function onMapClicked(props) {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
    // Popup.alert('I am alert, nice to meet you');
  }

  // function centerMoved (mapProps, map) {
  //   // ...
  // }

  // function onMouseoverMarker (props, marker, e) {
  //   // ...
  // }

  // get icp info
  function fetchIcpData() {
    getFromBackend("/icecreamparlor/filter")
      .then(res => res.json())
      .then(
        (response) => {
          setFiltericps(response)
        },
        errorPopup
      )
  }

  function setFilter(event) {
    event.preventDefault();
    let filterScore = Number.parseFloat(event.target.getAttribute('value'));
    setIcpFilterScore(filterScore);
  }

  function clearFilter() {
    setIcpFilterScore(1);
  }

  function filterScore(item) {
    return (icpFilterScore === 1) ||
           ((item.score !== null) &&
            (item.score !== undefined) &&
            (Number.parseFloat(item.score.average).toFixed(1) >= icpFilterScore));
  }

  function scoreValueAsText(item) {
    return (item.score === undefined) || (item.score === null) ?
        '' :
        ' (Vurdering: ' +
        Number.parseFloat(item.score.average)
            .toFixed(1)
            .replace(/\./g, ',') +
        ')';
  }

  const currentZoom = (browserLocation !== null ? browserZoom : defaultZoom);
  const currentLocation = (browserLocation !== null ? browserLocation : defaultCenter);


  return (
      <div>
        <Popup />
        <Menu width={'280px'} isOpen={false}>
          <span className="filterInitialText">Vælg filter:</span>
          <button id="filter1" type="button" className="filter-btn" onClick= { setFilter } value="1">Alle vurderinger</button>
          <button id="filter2" type="button" className="filter-btn" onClick= { setFilter } value="2">Vurderinger >= 2</button>
          <button id="filter3" type="button" className="filter-btn" onClick= { setFilter } value="3">Vurderinger >= 3</button>
          <button id="filter4" type="button" className="filter-btn" onClick= { setFilter } value="4">Vurderinger >= 4</button>
          <span className="filterText">"Alle vurderinger" indeholder også ishuse som endnu ikke er vurderet af findis.dk</span>
          <LogoLink/>
        </Menu>
        <ShowFilter visible={ icpFilterScore !== 1 } filterScore={ icpFilterScore } onClick={ clearFilter } />

        <InstagramProfileLink/>
        <GeneralMailLink/>
        <ResetLink/>

        <Map google={props.google}
             initialCenter={{lat: defaultCenter.lat, lng: defaultCenter.lng}}
             centerAroundCurrentLocation={true}  //{lat: currentLocation.lan, lng: currentLocation.lng}}
          // center={{lat: defaultCenter.lan, lng: defaultCenter.lng}}
             zoom={currentZoom}
          // onDragend={centerMoved}
          // onMouseover={onMouseoverMarker}
             onClick={onMapClicked}
             onIdle={handleMapIdle}
             visible={visible}>

          {
            (mapLoaded) &&
            (showLocation) &&
              <Marker id='current'
                      title={'Din position'}
                      position={{lat: currentLocation.lat, lng: currentLocation.lng}}
                      icon={{url: "/media/dot.png"}}/>
          }

          { // List of icps
            (mapLoaded) &&
            (filtericps !== null) &&
            (filtericps.length > 0) &&
            (filtericps.map(item =>
                (filterScore(item)) &&
                (
              <Marker id={item.id}
                      ref={(item.id === Number(props.icpId) && !markerAlreadyMounted) ? onMarkerMounted : null}
                      key={item.id}
                      name={item.name}
                      title={item.name + scoreValueAsText(item)}
                      onClick={onMarkerClick}
                      address={item.address}
                      commercialProtection={item.protect}
                      ratingValue={item.ratingValue}
                      ratingCount={item.ratingCount}
                      brand={item.brand}
                      type={item.type}
                      position={{lat: item.lat, lng: item.lng}}
                      icon={{url: "/media/wafle.png"}}
                      location={'('+item.lat+','+item.lng+')'}
                      igLink={item.igLink}
                      score={item.score}
              />
                )
            ))
          }

          <InfoWindow
            // onOpen={onInfoWindowOpen}
            // onClose={onInfoWindowClose}
            marker={activeMarker}
            visible={showingInfoWindow}>
              <InfoBox selected={selectedPlace} />
          </InfoWindow>
        </Map>
      </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCxtbA8tfmcJMaRT6gbmPhIFH04ru-sTXE",
  version: 'weekly'
})(WebHome);
