import React from "react";

const ResetLink = () => {

    const link = '/';

    return (
      <div className="quick-icon-box" style={{top: '235px'}}>
        <div className="quick-icon-cell">
          <a href={link} title="Genindlæs visning"><span className="fa-regular fa-arrow-rotate-left fa-2x icon-color"></span></a>
        </div>
      </div>
    )

}

export default ResetLink;
