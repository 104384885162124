
// console.info('NODE_ENV: [%s]', process.env.NODE_ENV);

export const Server = {

  // When development set host to https://dev.findis.dk:8443
  // Otherwise current url domain and port is used
  HOST: ((process.env.NODE_ENV === 'development') ? 'https://dev.findis.dk:8443' : ''),

  REST_PATH: '/rest',
  LOGIN_PATH: '/login'
};
