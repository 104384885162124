import React from "react";

const ContactFindIsLink = (props) => {

    const link = 'mailto:info@findis.dk?subject=' +
                 encodeURI('Mail fra Findis.dk vedr. nr. ' + props.id) +
                 '&body=' + encodeURI('Vedr. nr.: ' + props.id + '\n\rNavn: ' + props.name + '\n\r');

    return (
        <tr>
          <td className="cellicon"><a href={link}><span className="fa-regular fa-envelope fa-2x icon-color"></span></a></td>
          <td className="celltext"><a href={link}>Kontakt findis.dk</a></td>
        </tr>
    )

}

export default ContactFindIsLink;
