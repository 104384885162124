import {Server} from './Server.js'
//import 'whatwg-fetch'

export function getFromBackend (pathStrParam, parametersStrParam) {

  const pathStr = pathStrParam;
  const parametersStr = (parametersStrParam != null ? parametersStrParam : '');
  const urlStr = Server.HOST + Server.REST_PATH + pathStr + parametersStr;
//  console.log('Get from backend url: [%s]', urlStr);

//  const csrfJsonStr = getCSRFToken();

//  if (csrfJsonStr.name) {
//    contentTypeStr['X-CSRF-TOKEN'] = csrfJsonStr.token;
//  }

  // return new Promise(resolve => { fetch(url,
  return fetch(urlStr);
//  return fetch(url,
//      {
//        method: 'GET',
//        headers: headers
//      });
//    .then(results=>{return results.json()});
  // });
}

export function postToBackend (pathStrParam, bodyStrParam, contentTypeStrParam, methodStrParam) {

  const urlStr = Server.HOST + Server.REST_PATH + pathStrParam;
  const bodyStr = bodyStrParam;
  const contentTypeStr = (contentTypeStrParam != null ? contentTypeStrParam : 'application/x-www-form-urlencoded')
  const methodStr = (methodStrParam != null ? methodStrParam : 'POST')
//  console.log('Post to backend url: [%s], method: [%s]', urlStr, methodStr);

  const csrfJsonStr = getCSRFToken();

  const headersStr = {
    'content-Type': contentTypeStr
  };

  if (csrfJsonStr.name) {
    headersStr['X-CSRF-TOKEN'] = csrfJsonStr.token;
  }

  // let bodyStr = JSON.stringify(body);

  // return new Promise(resolve => {
  //   setTimeout(() => {
  return fetch(urlStr,
      {
        headers: headersStr,
        method: methodStr,
        body: bodyStr,
      });
  //     resolve();
  //   }, 1000);
  // });
}


export function postToLogin (pathStrParam) { // { username, password) {

  const urlStr = Server.HOST + Server.LOGIN_PATH + pathStrParam;
  // const url = "http://user:password@localhost:8080/login";
  const methodStr = 'POST';
  // const method = 'GET';
  // const token = document.querySelector('meta[name=_csrf]').getAttribute("content");
  // const header = document.querySelector('meta[name=_csrf_header]').getAttribute("content");
  // console.log('CSRF: ('+token+':'+header+')');

  const authorization = btoa("user:password");
  console.log("authorization: " + authorization)

  const headersStr = {
    // 'Accept': 'application/json',
    // 'Content-Type': 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded',
    // 'Authorization': 'Basic ' + new Buffer('user:password').toString('base64')
    'Authorization': 'Basic ' + btoa("user:password")
    // 'Authorization': btoa(username + ":" + password)
  };

  // base64_encode
  // let bodyStr = btoa(username + ":" + password);
  let bodyStr = null;

//  console.log("postToLogin: url=[" + urlStr + "]" +
//    ", method=[" + methodStr + "]" +
//    ", headers=[" + headers.Authorization + "]" +
//    ", body=[" + bodyStr + "]");

  return fetch(urlStr,
    {
      method: methodStr,
      // mode : 'no-cors',
      headers: headersStr,
      body: bodyStr,
    });
  //     resolve();
  //   }, 1000);
  // });
}


function getCSRFToken() {

  const csrfNameStr = document.querySelector('meta[name=csrf-token-name]');
  const csrfTokenStr = document.querySelector('meta[name=csrf-token]');

  const csrfJsonStr = {
    name: csrfNameStr !== null ? csrfNameStr.getAttribute('content') : '',
    token: csrfTokenStr !== null ? csrfTokenStr.getAttribute('content') : ''
  };
//  console.log('Csrf: ['+JSON.stringify(csrfJsonStr) +']');

  return csrfJsonStr;
}
