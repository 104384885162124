import React from "react";

const IcecreamType = (props) => {

    return ""

    let text = 'Ukendt istype(r)';

    if (props.value === 1) {
        text = "Italiensk is"
    }
    else if (props.value === 2) {
        text = "Flødeis"
    }
    else if (props.value === 3) {
        text = "Sorbet is"
    }

    return (
        <tr><td valign="top"><div style={{padding:'2px 1px 2px 1px'}}>{text}</div></td></tr>
    )
}

export default IcecreamType;
