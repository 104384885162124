import React from "react";

const ShowFilter = (props) => {

    return (
        props.visible &&
        (
      <div className="filter-box">
        <div className="filter-cell">
            <div className="filter-cell-v-align" onClick={props.onClick}>
                <span className="filter-cell-text">Nulstil filter >= {props.filterScore}</span><span className="fa-regular fa-xmark icon-color filter-remove-icon"></span>
            </div>
        </div>
      </div>
        )
    )

}

export default ShowFilter;
