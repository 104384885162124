import React from "react";

const IcecreamBrand = (props) => {

    return ""

    let text = 'Ukendt isleverandør';

    if (props.value === 1) {
        text = "Hjemmelavet is"
    }
    else if (props.value === 2) {
        text = "Premier is"
    }
    else if (props.value === 3) {
        text = "Frisko is"
    }
    else if (props.value === 4) {
        text = "Hansen is"
    }
    else if (props.value === 5) {
        text = "Kastberg is"
    }
    else if (props.value === 6) {
        text = "Vebbestrup Ismejeri"
    }

    return (
        <tr><td valign="top"><div style={{padding:'2px 1px 2px 1px'}}>{text}</div></td></tr>
    )
}

export default IcecreamBrand;
